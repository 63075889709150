<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 500px; overflow-y: hidden"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
    />
    <!--<el-button @click="demo">测试</el-button>-->
  </div>
</template>
<script>
import Vue from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { DomEditor } from "@wangeditor/editor";
import {uploadImg} from "../../../../api/setting";
export default Vue.extend({
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    html: {
      get() {
        return this.value;
      },
      set(html) {
        this.$emit("input", html);
      },
    },
  },
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      toolbarConfig: {},
      editorConfig: { placeholder: "请输入内容..." },
      mode: "simple", // or 'simple'
    };
  },
  methods: {
    demo() {
      console.log(this.editor.getHtml());
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      this.$nextTick(() => {
        if(this.disabled){
          this.editor?.disable()
        }else {
          this.editor?.enable()
        }
        // 配置编辑栏
        const toolbar = DomEditor.getToolbar(this.editor);
        const curToolbarConfig = toolbar.getConfig();
        curToolbarConfig.excludeKeys = ["fullScreen", "insertVideo"];
        this.setUploadImgConfig();
      });
    },
    setUploadImgConfig() {
      // eslint-disable-next-line no-unused-vars
      let uploadImgConfig = this.editor.getMenuConfig("uploadImage");
      console.log(uploadImgConfig)
      uploadImgConfig.customUpload = async (file, insertFn)=>{
        console.log(file)
        const formData = new FormData()
        formData.append('file',file)
        const res = await uploadImg(formData)
        console.log(res)
        insertFn(res, '', res)
      }
      /*this.editorConfig.MENU_CONF['uploadImage'] = {

        server: "/api/admin/sys-file/uploadFile",
        headers: {
          authorization: token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        onBeforeUpload(file) {
          return file
        },

        // 上传进度的回调函数
        onProgress(progress) {
          console.log('progress', progress)
        },

        // 单个文件上传成功之后
        onSuccess(file, res) {
          console.log(`${file.name} 上传成功`, res)
        },

        // 单个文件上传失败
        onFailed(file, res) {
          console.log(`${file.name} 上传失败`, res)
        },

        // 上传错误，或者触发 timeout 超时
        onError(file, err, res) {
          console.log(`${file.name} 上传出错`, err, res)
        },
      };*/
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
});
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
