import request from '@/utils/request'
// 新建文章
export function addArticle(data) {
  return request({
    url: '/admin/article',
    method: 'post',
    data
  })
}
// 编辑文章
export function editArticle(data) {
  return request({
    url: '/admin/article',
    method: 'put',
    data
  })
}
// 分页查询文章
export function pageArticle(params) {
  return request({
    url: '/admin/article/page',
    method: 'get',
    params
  })
}
//删除文章
export function deleteArticle(id) {
  return request({
    url: '/admin/article/' + id,
    method: 'delete'
  })
}
//H5页面查询
export function h5Article(code) {
  return request({
    url: `/admin/article/code/${code}`,
    method: 'get',
  })
}
