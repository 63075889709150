<template>
  <div class="role-container">
    <Jump :jumpList="jumpList" />
    <NavMenu :activeUrl="activeUrl" />
    <div class="page-wrap">
      <chil-menu />
      <div class="role-wrap">
        <avue-crud
          ref="crud"
          :option="tableOption"
          :data="list"
          :page.sync="page"
          v-model="form"
          :table-loading="listLoading"
          @on-load="getList"
          @search-change="searchChange"
          @refresh-change="refreshChange"
          @size-change="sizeChange"
          @current-change="currentChange"
          @row-update="update"
          @row-save="create"
        >
          <template slot="menuLeft">
            <el-button
              class="filter-item"
              type="primary"
              size="small"
              icon="el-icon-plus"
              @click="handleCreate"
              v-auth="['setting-system-articleManagement-add']"
              >新增
            </el-button>
          </template>
          <template slot="menuRight">
            <div style="display: flex">
              <div class="line-alignment">
                <el-input
                  style="margin-right: 20px"
                  placeholder="请输入标题"
                  v-model="search.title"
                />
                <el-select v-model="search.status" placeholder="请选择状态">
                  <el-option label="启用" value="1" />
                  <el-option label="禁用" value="0" />
                </el-select>
                <el-button
                  class="filter-item"
                  type="primary"
                  size="small"
                  icon="el-icon-search"
                  style="margin-left: 40px"
                  @click="
                    page.currentPage = 1;
                    getList(page, search);
                  "
                  >搜索
                </el-button>
                <el-button
                  class="filter-item"
                  type="primary"
                  size="small"
                  icon="el-icon-delete"
                  @click="reset"
                  >清空
                </el-button>
              </div>
            </div>
          </template>
          <template slot="contentForm">
            <editor v-model="form.content" :disabled="editorDisabled"></editor>
          </template>
          <template slot="menu" slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="handleView(scope.row, scope.index)"
              >查看
            </el-button>
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row, scope.index)"
              v-auth="['setting-system-articleManagement-edit']"
              >编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              icon="el-icon-delete"
              @click="handleDelete(scope.row, scope.index)"
              v-auth="['setting-system-articleManagement-del']"
              >删除
            </el-button>
          </template>
        </avue-crud>
      </div>
    </div>
  </div>
</template>

<script>
import Jump from "@/components/jump";
import NavMenu from "../../setting/navMenu.vue";
import editor from "./editor/index.vue";
import { tableOption } from "./tableOption";
import ChilMenu from "../../setting/chilMenu.vue";
import {
  addArticle,
  deleteArticle,
  editArticle,
  pageArticle,
} from "../../../api/article";
export default {
  name: "role",
  data() {
    return {
      activeUrl: "/setting/system",
      searchForm: {},
      tableOption: tableOption,
      dsScopeData: [],
      treeData: [],
      checkedKeys: [],
      checkedDsScope: [],
      defaultProps: {
        label: "name",
        value: "id",
      },
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
      },
      menuIds: "",
      list: [],
      listLoading: true,
      form: {},
      roleId: undefined,
      roleCode: undefined,
      rolesOptions: undefined,
      dialogPermissionVisible: false,
      search: {},
      jumpList: [{ name: "首页" }, { name: "权限管理" }, { name: "角色管理" }],
      editorDisabled:false
    };
  },
  components: {
    ChilMenu,
    NavMenu,
    Jump,
    editor,
  },
  methods: {
    getList(page, params) {
      for (let key in params) {
        if (!params[key]) {
          delete params[key];
        }
      }
      this.listLoading = true;
      pageArticle({
        current: page.currentPage,
        size: page.pageSize,
        ...params,
      })
        .then((response) => {
          this.list = response.data.records;
          this.page.total = response.data.total;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    refreshChange() {
      this.getList(this.page);
    },
    reset() {
      this.search = {};
    },
    searchChange(form, done) {
      this.searchForm = form;
      this.page.currentPage = 1;
      this.getList(this.page, form);
      done();
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    currentChange(current) {
      this.page.currentPage = current;
    },
    handleView(row,index){
      this.editorDisabled=true
      this.$refs.crud.rowView(row,index);
    },
    handleCreate() {
      this.editorDisabled=false
      this.$refs.crud.rowAdd();
    },
    handleUpdate(row, index) {
      this.editorDisabled=false
      this.$refs.crud.rowEdit(row, index);
    },
    // 删除
    handleDelete(row) {
      this.$confirm(
        `是否确认删除标题为"${row.title}"的数据项?`,
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        }
      )
        .then(function () {
          return deleteArticle(row.id);
        })
        .then(() => {
          this.getList(this.page);
          this.$notify.success("删除成功");
        });
    },
    // 新增
    create(row, done, loading) {
      addArticle(this.form)
        .then(() => {
          this.getList(this.page);
          done();
          this.$notify.success("创建成功");
        })
        .catch(() => {
          loading();
        });
    },
    // 更新
    update(row, index, done, loading) {
      editArticle(this.form)
        .then(() => {
          this.getList(this.page);
          done();
          this.$notify.success("修改成功");
        })
        .catch(() => {
          loading();
        });
    },
  },
};
</script>

<style lang="scss">
.avue-dialog .el-dialog {
  width: 35% !important;
}
.page-wrap {
  display: flex;
  .role-wrap {
    background: transparent;
    box-sizing: border-box;
    padding: 0 20px;
    flex: 1;
    width: 0;
  }
}
.role-container {
  padding: 0 10px;
  .user-wrap {
    display: flex;
    .user-tree {
      width: 230px;
      flex-shrink: 0;
      padding-top: 20px;
    }
  }
  .user {
    height: 100%;

    &__tree {
      padding-top: 3px;
      padding-right: 20px;
    }

    &__main {
      .el-card__body {
        padding-top: 0;
      }
    }
  }
  .el-tree {
    padding-top: 15px;
    height: calc(100vh - 300px);
    background: #0c265a;
  }
  .el-card {
    background-color: transparent;
    border: none;
  }
  .avue-crud {
    background: transparent;
  }
  .avue-crud__menu {
    background: transparent;
  }

  ::v-deep .single-row {
    font-family: "MicrosoftYaHei";
  }
  ::v-deep .double-row {
    background: #0c265a !important;
    color: #ffffff;
    font-size: 14px;
    font-family: "MicrosoftYaHei";
    font-weight: 400;
  }
  .avue-crud .el-table th {
    background: rgb(17, 50, 117);
    color: rgb(163, 214, 255);
    border: none;
  }
  .el-table tr {
    color: #ffffff;
    background-color: #081e49;
  }
  .el-table--striped
    .el-table__body
    tr.el-table__row--striped
    td.el-table__cell {
    background-color: #0c265a;
    border-bottom: 1px solid #1d3b6a;
    border-top: 1px solid #1d3b6a;
  }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 0px;
  }
  .el-table th.el-table__cell {
    color: #a3d6ff;
    background-color: #113275;
  }
  .el-table--border::after,
  .el-table--group::after,
  .el-table::before,
  .el-table__fixed-right::before,
  .el-table__fixed::before {
    background-color: #1d3b6a;
  }
  .el-table--border,
  .el-table--group {
    border: none;
    margin-top: 20px;
  }
  .el-table__body tr.hover-row.current-row > td.el-table__cell,
  .el-table__body
    tr.hover-row.el-table__row--striped.current-row
    > td.el-table__cell,
  .el-table__body tr.hover-row.el-table__row--striped > td.el-table__cell,
  .el-table__body tr.hover-row > td.el-table__cell {
    background-color: #113275 !important;
  }
  .el-table--border .el-table__cell,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: none;
  }
  .el-pagination__total {
    color: #a3d6ff;
  }
  .el-tag {
    border-color: #1d3b6a;
    background-color: transparent;
  }
  .avue-crud {
    width: 95%;
  }

  // button {
  //   height: 36px;
  //   background: linear-gradient(0deg, #1f79ff, #23b4ff);
  //   border-radius: 2px;
  //   line-height: 10px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
  .el-button--small.is-circle {
    display: none;
  }
  .el-card__body {
    padding-top: 20px !important;
  }
  .el-scrollbar__wrap {
    overflow: auto;
  }
  .el-radio.is-bordered {
    border: 1px solid rgba(73, 108, 180, 0.36) !important;
  }
  .el-radio {
    color: #a3d6ff;
  }

  // .el-select-dropdown__list{
  //   background: #06193C;
  // }
  .el-pagination__jump {
    color: #a3d6ff;
  }
  .el-table__empty-block {
    background: #0c265a;
  }
  .avue-empty__desc {
    color: #a3d6ff;
  }
  .avue-empty__image img {
    display: none;
  }
  .avue-empty__image {
    height: 0px !important;
  }
}
.el-select-dropdown__list {
  background: #06193c;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  background-color: #0c265a;
}
.avue-dialog .el-tag.el-tag--info {
  color: #a3d6ff;
  background: #0c265a;
  border: 1px solid rgba(73, 108, 180, 0.36) !important;
}
.avue-dialog .el-dialog__header {
  background: url("../../../assets/dialog_header_bk.png");
  border-bottom: none !important;
  .el-dialog__headerbtn {
    top: 20px !important;
  }
}
.avue-dialog .el-dialog__title {
  color: #a3d6ff !important;
  // padding-left: 45px;
  font-size: 18px;
  font-weight: bold !important;
}
.avue-dialog .el-dialog__body {
  background: #0c265a;
  &::-webkit-scrollbar {
    width: 2px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 80px !important;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #0a4696;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    background: #0c265a;
  }
}
.avue-dialog .avue-dialog__footer {
  background: #0c265a;
  border: none !important;
}
.el-collapse-item__wrap {
  background: transparent;
}
.avue-group .el-collapse,
.avue-group .el-collapse-item__wrap {
  border-color: transparent;
}
.avue-form__group--flex {
  .el-form-item__label {
    color: #a3d6ff;
  }
  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    // display: flex;
  }
}
.avue-dialog .el-tree {
  width: 300px;
}
.permission-dialog {
  .el-dialog__headerbtn {
    top: 10px;
  }
  .el-dialog__header {
    background: url("../../../assets/dialog_header_bk.png");
    border-bottom: none !important;
  }
  .el-dialog__title {
    color: #a3d6ff !important;
    padding-left: 45px;
    font-size: 18px;
    font-weight: bold !important;
  }
  .el-dialog__body {
    background: #0c265a;
  }
  .el-dialog__footer {
    background: #0c265a;
    border: none !important;
  }
  .el-tree {
    overflow-y: scroll;
    height: 500px !important;
  }
}
.el-dialog{
  background-color: #152559;
}
.avue--detail .el-form-item--small.el-form-item{
  background-color: #152559;
}
.avue--detail .el-form-item__content{
  background-color: #152559;
}
.avue--detail .el-input.is-disabled .el-input__inner{
  background-color: #172b50;
  border: none;
  color: rgba(163, 214, 255, 0.36);
}
.avue--detail .el-form-item__content{
  border-left: 1px solid #326394;
}
.avue--detail .el-col{
  border-bottom: 1px solid #326394;
  border-right: 1px solid #326394;
}
.avue--detail .el-row{
  border-top: 1px solid #326394;
  border-left: 1px solid #326394;
}
</style>
<style lang="scss" scoped>
::v-deep .el-radio__label {
  color: #fff;
}
</style>
